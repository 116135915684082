
.sidebar .nav-link.active-menu-item{
  background-color: rgb(40 47 60) !important;
}
.newItem.disabled{
  opacity: 0.5;
  pointer-events: none;
  max-height: 200px;
  overflow: scroll;
}
.newItem.disabled td, .newItem.disabled th{
  background-color: #f0f0f0;
  color: #a0a0a0;
}
.searchResult{
  max-height: 200px;
  overflow: scroll;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: absolute;
  bottom: 0px;
  z-index: 1000;
  width: 100%;
}
.searchResult thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.fullScreenModal .modal-dialog{
  margin: 0 !important;
}

.myTopRightToaster {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 250px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  opacity: 2;
  visibility: visible;
  z-index: 11000;

}
.myTopRightToaster.default{
  background-color: #a0a0a0;
  color:black;
}
.myTopRightToaster.error{
  background-color: #d33535;
  color: white;
}
.myTopRightToaster.success{
  background-color: green;
  color: white;
}

.couponField.invalid input{
  border: 1px solid #d33535 !important;

}
.couponField.valid input{
  border: 1px solid green !important;

}

.orderItem.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.orderItem.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: pre-line;
}

.orderItem.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.product-name-tooltip {
  position: relative;
  cursor: pointer;
}

.tooltip-content {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.product-name-tooltip:hover .tooltip-content {
  display: block;
}

.product-name-tooltip:hover .tooltip-content tr{
  padding: 0;
}
.product-name-tooltip:hover .tooltip-content tr{
  padding: 2px;
}

.font-weight-bold{
  font-weight: 700 !important;
}
.input-container {
  position: relative;
  display: inline-block;
}

input.couponField {
  padding-right: 30px;
  width: 100%;
}

.clear-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #aaa;
}

.clear-button:hover {
  color: #333;
}

.ui.icon.input{
  height: auto !important;
}

.swal2-buttons-row {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.swal2-yes-btn {
  background-color: #3085d6;
}

.swal2-no-btn {
  background-color: lightgray;
}

.swal2-info-icon {
  font-size: 40px;
  width: 40px;
  height: 40px;
}

.add-lorry .form-group{
  margin-bottom: 10px;
}
.add-lorry .form-group{
  font-size: 13px;
}
.dropdown-menu .dropdown-item{
  cursor: pointer;
}




.accordion {
  max-width: 100%;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.accordion-item {
  border-bottom: 1px solid #ddd;
}

.accordion-header {
  padding: 15px;
  background-color: #f7f7f7;
  cursor: pointer;
}

.accordion-header h2 {
  margin: 0;
}

.accordion-content {
  padding: 15px;
  background-color: #fff;
}

.accordion-content p {
  margin: 0;
}

/* General styling for the editor container */

.editable {
  /* border: 1px solid #ccc; */
  font-size: 14px;
  background-color: #fff;
  padding: 10px;
  min-height: 80px;
  overflow: auto;
  resize: both; /* Allow resizing both horizontally and vertically */
  min-width: 100%; /* Minimum width to avoid collapsing */
  max-width: 100%;
  min-height: 50px; /* Minimum height to avoid collapsing */
}

.editable:empty:before {
  content: attr(data-placeholder);
  color: #aaa;
  pointer-events: none;
}

.editable:focus-visible {
  outline: none;
  border: 1px solid #ccc; /* Keep the border as it is */
}

/* Toolbar styling */
.toolbar {
  display: ruby-text;
  border: 1px solid #ccc;
  border-bottom: none;
  padding: 5px;
  background-color: #f3f3f3;
}

/* Toolbar button styling */
.toolbar button {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  /* font-size: 16px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 100;
}

.toolbar .fa-icon {
  font-weight: 100 !important; /* Adjust font weight as needed */
}

.toolbar button:hover {
  background-color: #e0e0e0;
}

.toolbar button:focus {
  outline: none;
}

/* Active toolbar button styling */
.toolbar button.active {
  background-color: #d0d0d0;
}

/* Styling for the editor and toolbar container */
.editor-container {
  border: 1px solid #ccc;
}

/* Table selection grid styling */
.table-grid {
  display: inline-grid;
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  padding: 5px;
  grid-template-columns: repeat(10, 20px);
  grid-template-rows: repeat(10, 20px);
}

.table-grid .cell {
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
}

.table-grid .cell:hover,
.table-grid .cell.selected {
  background-color: #cce5ff;
  border-color: #99ccff;
}

.cell.selected {
  background: #007bff;
}

th, td {
  position: relative;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
  height: 100%;
  cursor: col-resize;
  background-color: #ccc;
  z-index: 1;
}

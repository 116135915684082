.parent {
  display: flex;
  font-size: 0;
  flex-wrap: wrap;
}
.child {
  display: inline-block;
  margin: 10px 0 0 10px;
  flex-grow: 1;
  width: calc(100% * (1 / 2) - 10px - 1px);
}
